import { useEffect, useRef, useState } from "react";
import React from "react";
import Link from "../kit/Link";

const DesktopHeader = () => {
  const [menu, setMenu] = useState(null);
  const menuRef = useRef(null);
  const BASE_HEIGHT = 80;
  const [menuHeight, setMenuHeight] = useState(BASE_HEIGHT);

  useEffect(() => {
    if (menu && menuRef.current) {
      setMenuHeight(
        BASE_HEIGHT +
          document.querySelector(`[data-target="${menu}"]`).clientHeight,
      );
    } else {
      setMenuHeight(BASE_HEIGHT);
    }
  }, [menu]);

  useEffect(() => {
    const desktopNav = document.querySelector(".desktopNav");
    let prevScrollPos = window.scrollY;

    if (desktopNav && !menu) {
      const handleOnScroll = () => {
        const currentScrollPos = window.scrollY;

        if (currentScrollPos <= 10) {
          return;
        }

        if (prevScrollPos > currentScrollPos) {
          // user has scrolled up
          desktopNav.classList.remove("pointer-events-none");
          desktopNav.classList.add("translate-y-[0%]");
          desktopNav.classList.remove("translate-y-[-100%]");
        } else {
          desktopNav.classList.add("pointer-events-none");
          desktopNav.classList.add("translate-y-[-100%]");
          desktopNav.classList.remove("translate-y-[0%]");
        }

        // update previous scroll position
        prevScrollPos = currentScrollPos;
      };

      window.addEventListener("scroll", handleOnScroll);

      return () => {
        window.removeEventListener("scroll", handleOnScroll);
      };
    }
  }, [menu]);

  return (
    <>
      <header
        className={`desktopNav sticky left-0 top-0 z-50 min-h-[80px] w-full transition-all duration-300 text-white`}
      >
        <div
          id="menu"
          ref={menuRef}
          style={{ height: `${menuHeight}px` }}
          className={`absolute left-0 top-0 h-auto w-full duration-[400ms] ${menu ? "bg-[#161617]" : "bg-[#000]"}`}
        >
          <div className="relative z-50 mx-auto h-[80px] px-[32px]">
            <div className="grid h-full grid-cols-12">
              <div className="col-span-3 flex items-center xl:col-span-6">
                <Logo />
              </div>
              <div className="col-span-9 flex items-center justify-end gap-5 xl:col-span-6 xl:gap-6">
                <div className="text-sm xl:text-base text-right">
                  <Link target="_blank" href="https://build.minima.global">
                    Build on Minima
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Backdrop */}
      {menu && (
        <div
          onMouseEnter={() => setMenu(null)}
          className="fixed left-0 top-0 z-40 h-full w-full bg-[rgba(0,0,0,0.75)] backdrop-blur-md"
        />
      )}
    </>
  );
};

const Logo = () => (
  <a href="/">
    <svg
      width="37"
      height="33"
      viewBox="0 0 37 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-black transition-all dark:fill-white`}
    >
      <path
        d="M28.8727 9.20966L27.2806 16.2518L25.2445 7.7553L18.1105 4.86191L16.1816 13.3737L14.4823 3.39225L7.34831 0.51416L0 32.9998H7.79227L10.0427 23.0183L11.742 32.9998H19.5496L21.4632 24.488L23.4993 32.9998H31.2915L36.022 12.0877L28.8727 9.20966Z"
        fill="#currentColor"
      />
    </svg>
  </a>
);

const Header = () => {
  return (
    <DesktopHeader />
  );
};

export default Header;
