import { useState } from "react";
import { Button } from "./Button";

const Hero = ({
  title,
  description,
  primaryButton,
  secondaryButton,
  customButton,
  video,
  image,
  mobileImage,
  overlay = true,
  maxHeight = undefined,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  let height = "";

  if (maxHeight) {
    height = "max-h-[420px] lg:max-h-[640px]";
  }

  return (
    <div id="hero" className={`hero relative block w-full ${height}`}>
      <div className="mx-auto h-full w-full">
        <div className="h-full w-full p-[16px] xl:p-[32px]">
          <div className="relative h-full w-full overflow-hidden bg-black">
            <div className="relative z-20 mx-auto h-full w-full">
              <div className="absolute bottom-0 left-0 flex items-center">
                <div className="max-w-[540px] p-6 drop-shadow-lg md:max-w-[400px] lg:max-w-[540px] lg:p-8 lg:px-10 lg:py-12 xl:max-w-[560px]">
                  <div className="w-full text-[28px] font-[500] leading-snug text-white lg:text-[40px]">
                    {title}
                  </div>
                  {description && (
                    <h2 className="mb-0 mt-3 text-white lg:mt-4">
                      {description}
                    </h2>
                  )}
                  {customButton && <div className="mt-6">{customButton}</div>}
                  {(!!primaryButton || !!secondaryButton) && (
                    <div className="mt-6 flex gap-3 lg:mt-10">
                      {primaryButton && (
                        <a
                          href={primaryButton.href}
                          target={primaryButton.external ? "_blank" : "_self"}
                          rel="noreferrer"
                        >
                          <Button>{primaryButton.label}</Button>
                        </a>
                      )}
                      {secondaryButton && (
                        <a
                          href={secondaryButton.href}
                          target={secondaryButton.external ? "_blank" : "_self"}
                          rel="noreferrer"
                        >
                          <Button theme="outline">
                            {secondaryButton.label}
                          </Button>
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {video && (
              <>
                <video
                  src={video}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  playsInline={true}
                  onPlay={() => setIsPlaying(true)}
                  className="hero-video absolute left-0 top-0 z-10 h-full w-full"
                />
                {overlay && (
                  <div
                    className={`absolute left-0 top-0 z-10 h-full w-full bg-black ${isPlaying ? "opacity-30" : "hidden"}`}
                  />
                )}
              </>
            )}
            {image && (
              <>
                <div
                  className={`absolute left-0 top-0 z-10 h-full w-full bg-cover bg-center ${mobileImage ? "hidden lg:block" : ""}`}
                  style={{ backgroundImage: `url(${image}` }}
                />
                {mobileImage && (
                  <div
                    className="absolute left-0 top-0 z-10 h-full w-full bg-cover bg-right lg:hidden"
                    style={{ backgroundImage: `url(${mobileImage}` }}
                  />
                )}
                <div
                  className={`absolute left-0 top-0 z-10 h-full w-full bg-black ${overlay ? "opacity-20" : "opacity-20 lg:hidden"}`}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
