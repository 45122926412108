import React from 'react';
import {HashRouter as Router, Routes, Route} from 'react-router-dom';

// MiniDapps Import
import MiniDapps from './pages/MiniDapps';

// Css Import
import './index.css';
import './hero.css';

const App = () => {
  return (
    <Router>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<MiniDapps />}/>
			</Routes>
    </Router>
  )
}

export default App;
