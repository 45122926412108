export const Link = ({
  href,
  target = "",
  children,
  label,
  large = false,
  linkedIn = false,
  download,
  onClick,
}) => {
  const additionalClassNames = large ? "text-xl" : "";
  return (
    <a
      href={href}
      target={target}
      rel="noreferrer"
      download={download}
      onClick={onClick}
      className={`flex cursor-pointer items-center gap-3 text-orange dark:text-lightOrange dark:hover:text-lighterOrange ${additionalClassNames}`}
    >
      {children ?? label}
      {linkedIn && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM8 19H5V8H8V19ZM6.5 6.732C5.534 6.732 4.75 5.942 4.75 4.968C4.75 3.994 5.534 3.204 6.5 3.204C7.466 3.204 8.25 3.994 8.25 4.968C8.25 5.942 7.467 6.732 6.5 6.732ZM20 19H17V13.396C17 10.028 13 10.283 13 13.396V19H10V8H13V9.765C14.396 7.179 20 6.988 20 12.241V19Z"
            fill="currentColor"
          />
        </svg>
      )}
      {!large && !linkedIn && (
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.0625 11L0 9.9375L8.4375 1.5H1V0H11V10H9.5V2.5625L1.0625 11Z"
            fill="currentColor"
          />
        </svg>
      )}
      {large && !linkedIn && (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.4 13L0 11.6L9.6 2H1V0H13V12H11V3.4L1.4 13Z"
            fill="currentColor"
          />
        </svg>
      )}
    </a>
  );
};

export default Link;
