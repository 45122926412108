import React from "react";

export const Button = ({
  children,
  type,
  label,
  theme = "primary",
  onClick,
  disabled = false,
}) => {
  let additionalClassNames;

  if (theme === "primary") {
    additionalClassNames =
      "text-black font-[500] lg:text-normal bg-lightOrange enabled:hover:bg-lighterOrange";
  }

  if (theme === "outline") {
    additionalClassNames =
      "text-white bg-transparent ring-1 ring-inset ring-lightOrange enabled:hover:ring-lighterOrange hover:ring-lighterOrange";
  }

  if (theme === "inactive") {
    additionalClassNames = "text-white bg-contrast3 hover:bg-lighterOrange";
  }

  return (
    <button
      type={type}
      className={`min-h-[38px] min-w-[50px] px-4 text-sm transition-colors duration-150 lg:min-h-[48px] lg:min-w-[64px] lg:px-5 lg:text-base ${additionalClassNames}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children ?? label}
    </button>
  );
};

export default Button;
